import Vue from 'vue';
import _laxios from '../_laxios';

const {newBrandModel} = __INITIAL_STATE__;

module.exports = {
	namespaced: true,
	state: Object.assign({
		response: {
			errorDescription: '',
			emptyFields: '',
			success: ''
		}
	}, newBrandModel),
	actions: {
		sendFormData({state, commit, rootState}, formData) {

			state.response = {errorDescription: ''};
			rootState.autoRia24.loader = true;

			_laxios.addBrandModel
				.request({data: formData })
				.then(response => {
					rootState.autoRia24.loader = false;
					commit('setResponse', response);
				})
				.catch(errorAdding => {
					commit('setResponse', {errorDescription: 'Ошибка сети или сервера, попробуйте немного позже', details: errorAdding.message});
					console.error('error adding new brand : [path: vue/../newBrandModel] : [sendFormData] : [hash 91hpgb]:', errorAdding.message);
					rootState.autoRia24.loader = false;
				});
		},
	},
	mutations: {
		setResponse(state, newData) {
			state.response = {...state.response, ...newData};
		},
	},
	getters: {
		response: state => state.response,
	}
};
