export default {
	messages: {
		ru: {
			'Выберите': 'Выберите',
			'Запрос на добавление': 'Запрос на добавление',
			'Закрыть': 'Закрыть',
			'Модели': 'Модели',
			'Правильное указание Бренда и Модели автомобиля обеспечит актуальность Вашего объявления на сайте': 'Правильное указание Бренда и Модели автомобиля обеспечит актуальность Вашего объявления на сайте',
			'Ваша заявка будет рассмотрена модераторами в течение': 'Ваша заявка будет рассмотрена модераторами в течение',
			'рабочего дня': 'рабочего дня',
			'Если Вы хотите предложить несколько брендов транспортных средств, отправьте письмо с необходимыми наименованиями на e-mail': 'Если Вы хотите предложить несколько брендов транспортных средств, отправьте письмо с необходимыми наименованиями на e-mail',
			'Для рассмотрения Вашей заявки модератором необходимо заполнить следующие поля : Категория': 'Для рассмотрения Вашей заявки модератором необходимо заполнить следующие поля : Категория',
			'Категория': 'Категория',
			'Отправить': 'Отправить',
			'О решении, принятом по Вашему запросу, Вы будете уведомлены письмом на e-mail': 'О решении, принятом по Вашему запросу, Вы будете уведомлены письмом на e-mail',
			'Предложенная Вами модель': 'Предложенная Вами модель',
			'автомобиля': 'автомобиля',
			'транспортного средства': 'транспортного средства',
			'будет опубликована на сайте после проверки модератором': 'будет опубликована на сайте после проверки модератором',
			'Здесь Вы можете разместить краткое описание данной Модели': 'Здесь Вы можете разместить краткое описание данной Модели.',
			'Например: седан Украинского производства': 'Например: седан Украинского производства',
			'Или ссылку на описание данной Модели в интернете': 'Или ссылку на описание данной Модели в интернете. Например: http://lanos.com.ua/models/',
			'Примечания': 'Примечания',
			'Например': 'Например',
			'Ваше имя': 'Ваше имя',
			'Введите корректный email адрес': 'Введите корректный email адрес',
			'Для рассмотрения Вашей заявки модератором необходимо заполнить следующие поля': 'Для рассмотрения Вашей заявки модератором необходимо заполнить следующие поля',
			'Ошибка сети или сервера, попробуйте немного позже': 'Произошла ошибка сети или временно не работает сервер, попробуйте повторить попытку немного позже, или сообщите об ошибке в службу техподдержки',
			'Неизвестная ошибка при получении ответа от сервера API': 'Неизвестная ошибка при получении ответа от сервера API',
			'Проверьте и заполните поля': 'Проверьте и заполните поля',
			'Model': 'Модель',
			'Marka': 'Бренд',
			'Модель': 'Модель',
			'Марка': 'Бренд',
			'Category': 'Категория',
			'Ваша заявка будет рассмотрена модераторами...': `Спасибо за Вашу помощь в улучшении сайта! Ваша заявка будет рассмотрена модераторами в течение 
            1 рабочего дня. Если Вы хотите предложить несколько`,
			'...транспортных средств, отправьте ...': 'транспортных средств, отправьте письмо с необходимыми наименованиями',
			'Marka exist': 'Такой Бренд уже есть',
			'Model exist': 'Такая Модель уже есть',
			'существует в категории': 'существует в категории',
			'Same request for marka and model is exist': 'Такой запрос для бренда и модели уже существует',
			'уже присутствует в базе auto.ria.com': 'уже присутствует в базе auto.ria.com',
			'в категории': 'в категории',
			'брендов': 'брендов',
		},
		uk: {
			'Выберите': 'Оберіть',
			'Запрос на добавление': 'Запит на додавання',
			'Закрыть': 'Закрити',
			'Модели': 'Моделі',
			'Правильное указание Бренда и Модели автомобиля обеспечит актуальность Вашего объявления на сайте': 'Вірно вказані Бренд і Модель транспорту забезпечить актуальність Вашої объяви на сайті',
			'Ваша заявка будет рассмотрена модераторами в течение': 'Ваша заявка буде розглянута модераторами протягом',
			'рабочего дня': 'робочого дня',
			'Если Вы хотите предложить несколько брендов транспортных средств, отправьте письмо с необходимыми наименованиями на e-mail': 'Якщо Ви бажаєте запропонувати декілька брендів транспортних засобів, відправте електронний лист з необхідними найменуваннями на e-mail',
			'Для рассмотрения Вашей заявки модератором необходимо заполнить следующие поля : Категория': 'Для розгляду Вашої заявки модератором необхідно заповнити наступні поля : Категорія',
			'Предложенная Вами модель': 'Запропонована Вами модель',
			'Предложенные Вами марка': 'Запропонований Вами бренд',
			'автомобиля': 'автомобіля',
			'транспортного средства': 'транспортного засобу',
			'уже присутствуют в базе auto.ria.com в категории': 'вже існує у базі auto.ria.com в категорії',
			'Категория': 'Категорія',
			'Отправить': 'Надіслати',
			'О решении, принятом по Вашему запросу, Вы будете уведомлены письмом на e-mail': 'Про рішення, прийняте по Вашому запиту, Ви будете сповіщені листом на e-mail',
			'Здесь Вы можете разместить краткое описание данной Модели': 'Тут ви можете розмістити стислий опис даної моделі.',
			'Например: седан Украинского производства': 'Наприклад: седан Українського виробництва.',
			'Или ссылку на описание данной Модели в интернете': 'Або посилання на опис даної Моделі в інтернеті. Наприклад: http://lanos.com.ua/models/',
			'Примечания': 'Примітки',
			'Например': 'Наприклад',
			'Ваше имя': 'Ваше ім\'я',
			'Введите корректный email адрес': 'Введіть корректну email адресу',
			'Для рассмотрения Вашей заявки модератором необходимо заполнить следующие поля': 'Для розгляду Вашої заявки модератором необхідно заповнити наступні поля',
			'Ошибка сети или сервера, попробуйте немного позже': 'Трапилась помилка мережі або тимчасово не працює сервер, спробуйте дещо пізніше або повідомте про помилку у службу техпідтримки',
			'Неизвестная ошибка при получении ответа от сервера API': 'Незрозуміла помилка при отриманні відповіді від сервера API',
			'Проверьте и заполните поля': 'Перевірте і заповніть поля',
			'Model': 'Модель',
			'Marka': 'Бренд',
			'Модель': 'Модель',
			'Марка': 'Бренд',
			'Category': 'Категорія',
			'Ваша заявка будет рассмотрена модераторами...': `Дякуємо за Вашу допомогу у покращенні роботи сайта! Ваша заявка буде розлянута модераторами протягом 
            1 робочего дня. Якщо Ви бажаєте запропонувати декілька`,
			'...транспортных средств, отправьте ...': 'транспортних засобів, надішліть електронний лист з необхідними назвами',
			'будет опубликована на сайте после проверки модератором': 'буде опублікована на сайті після перевірки модератором',
			'и': 'та',
			'существует в категории': 'існує в категорії',
			'Marka exist': 'Такий Бренд вже існує',
			'Model exist': 'Така Модель вже існує',
			'Same request for marka and model is exist': 'Такий запит для марки та моделі вже існує',
			'уже присутствует в базе auto.ria.com': 'вже існує в базі auto.ria.com',
			'в категории': 'в категорії',
			'брендов': 'брендів',
		},
	}
};
