import Cookies from '../../helpers/cookie';

const storeModule = require('../../store/newBrandModel');
const autoRia24 = require('../../store/autoRia24');

import {mapGetters, mapActions, mapMutations} from 'vuex';
import Loader from '../AutoRia24/common/Loader.vue';

export default {
	name: 'NewBrandModel',
	components: { Loader },
	i18n: require('./i18n').default,
	init({store}) {
		store.registerModule('autoRia24', autoRia24);
		store.registerModule('newBrandModel', storeModule);
	},
	data() {
		return {
			isModelAdd: false,
			validation: {
				valid: true,
				message: '',
				fields: []
			},
			userId: '',
			userName: '',
			userEmail: '',
			categoryId: '0',
			brandId: 0,
			brandName: '',
			brandNameExample: this.$t('Например') + ': Daewoo',
			modelName: '',
			modelNameExample: this.$t('Например') + ': Lanos',
			objectDescr: ''
		};
	},
	computed: {
		...mapGetters({
			langId: 'lang/id',
			userData: 'Common/userData', // email, name
			categories: 'shared/categories/l10n', // dropdown select category
			brandsForCategory: 'shared/brands/getByCategory', // brand dropdown select
			response: 'newBrandModel/response',
		}),
		marks() {
			if (this.categoryId && this.categoryId !== '0') {
				return this.brandsForCategory(this.categoryId);
			} else {
				return {};
			}
		},
	},
	methods: {
		...mapActions({
			fetchBrands: 'shared/brands/fetchByCategory',
			getUserData: 'Common/userData',
			getCategories: 'shared/categories/fetch',
			getMarksByCategory: 'shared/brands/fetchByCategory',
			sendFormData: 'newBrandModel/sendFormData'
		}),
		...mapMutations({
			setLoader: 'autoRia24/setLoader',
		}),
		validate() {

			let fields = [];
			let emailRegexp = /[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}/igm;
			if (!emailRegexp.test(this.userEmail)) {
				fields.push(this.$t('Введите корректный email адрес'));
			}

			if (!this.userName) {
				fields.push(this.$t('Ваше имя'));
			}

			if (!this.userEmail) {
				fields.push('Email');
			}

			if (!this.categoryId || this.categoryId === '0') {
				fields.push(this.$t('Категория'));
			}

			if ((this.isModelAdd && this.brandId === '0') || this.brandName === '') {
				fields.push('Марка');
			}

			if (this.modelName === '') {
				fields.push('Модель');
			}

			this.validation = {
				valid: fields.length === 0,
				message: 'Для рассмотрения Вашей заявки модератором необходимо заполнить следующие поля',
				fields
			};

		},
		submitFormData() {
			this.validate();
			if (this.validation.valid) {
				let {langId, userId, userName, userEmail,
					categoryId, brandId, brandName, modelName, objectDescr} = this;
				this.sendFormData({langId, userId, userName, userEmail,
					categoryId, brandId, brandName, modelName, objectDescr});
			}
		},
		onCategorySelectChanged() {
			if (this.isModelAdd) {
				this.brandId = '0';
				this.brandName = '';
				this.getMarksByCategory(this.categoryId);
			}
		},
		onBrandChanged() {
			if (!this.isModelAdd) {
				return;
			}
			if (this.brandId && this.brandId !== '0' && this.marks && this.marks[this.brandId]) {
				this.brandName = this.marks[this.brandId].name;
			} else {
				this.brandName = '';
			}
		},
	},
	created() {

		let {categoryId = '0', brandId} = this.$route.query;
		this.categoryId = categoryId;
		this.isModelAdd = Boolean(brandId);
		this.brandId = brandId || '0';

	},
	mounted() {

		this.setLoader(true);

		Promise.all([
			this.getUserData({ Cookie: { PSP_ID: Cookies.get('PSP_ID') } })
				.then(userData=>{
					this.userName = userData.userName;
					this.userEmail = userData.userEmail;
					this.userId = userData.userId;
				})
				.catch(errorUserData=>{
					console.error('error get user data : [path: vue/../NewBrandModel] : [mounted]', errorUserData.message);
				}),
			this.getCategories()
				.then(() => this.getMarksByCategory(this.categoryId))
				.then(() => this.onBrandChanged())
				.catch(errorGetCategories => {
					console.error('error get categories : [path: vue/../NewBrandModel] : [mounted]', errorGetCategories.message);
				})

		])
			.then(()=> this.setLoader(false))
			.catch(errorAll=>{
				console.error('errors while loading all data for fill : [path: vue/../NewBrandModel] : [mounted]', errorAll.message);
				this.setLoader(false);
			});

	}

};
